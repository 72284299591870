import React, { useState, useEffect } from "react";
import SupportIcon from "../assets/svg/contact-us.svg";
import Leaderboards from "../assets/svg/statistics-leaderboard.svg";
import QuizHistory from "../assets/svg/quiz-history.svg";
import Notifications from "../assets/svg/notification.svg";
import HomeIcon from "../assets/svg/dashboard-icon.svg";
import "../scss/ui/sidebar.scss";
import { NavLink } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

export default function Sidebar() {
  const [collapsed, setCollapsed] = useState(false);



const toggleSidebar = () => {
  // Only collapse if screen is larger than 768px
  if (window.innerWidth > 768) {
    setCollapsed((prevState) => !prevState);
  }
};

// Optionally, you can also add an event listener to handle window resizing dynamically
useEffect(() => {
  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setCollapsed(false); // Automatically open the sidebar on mobile
    }
  };

  window.addEventListener("resize", handleResize);

  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);


  const menuItems = [
    { path: "/", icon: HomeIcon, text: "Boshqaruv paneli" },
    { path: "/leaderboards", icon: Leaderboards, text: "Peshqadamlar" },
    { path: "/quiz-history", icon: QuizHistory, text: "Viktorina tarixi" },
  ];

  return (
    <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
      <div className="sidebar-content">
        <div className="sidebar-logo">{!collapsed && <span>QuizApp</span>}</div>

        <nav className="sidebar-menu">
          {menuItems.map((item) => (
            <NavLink
              key={item.path}
              to={item.path}
              className={({ isActive }) =>
                `sidebar-item ${isActive ? "active-link" : ""}`
              }
            >
              <div className="item-content">
                <img src={item.icon} alt={`${item.text} Icon`} />
                {!collapsed && <span>{item.text}</span>}
              </div>
            </NavLink>
          ))}
        </nav>
      </div>

      <button
        className="sidebar-toggle"
        onClick={toggleSidebar}
        aria-label={collapsed ? "Expand sidebar" : "Collapse sidebar"}
      >
        {collapsed ? <FaChevronRight /> : <FaChevronLeft />}
      </button>
    </div>
  );
}
