import React, { useState } from 'react';
import GuestModeWarning from './GuestModeWarning';

export default function Leaderboards() {
  const [showWarning, setShowWarning] = useState(false);

  const handleAction = () => {
    const isGuest = localStorage.getItem('guestMode') === 'true';
    if (isGuest) {
      setShowWarning(true);
      return;
    }
    // Normal functionality here
  };


  // useEffect(() => {
  //   const token = localStorage.getItem("authToken");

  //   if (token && isTokenExpired(token)) {
  //     toast.error("Sessiya eskirdi. Iltimos hisobingiz bilan qaytadan kiring!");
  //     navigate("/login"); // Navigate immediately instead of showing a modal
  //   }
  // }, [navigate]);
  
  return (
    <div>
      {/* <h1>Tez orada ishga tushadi!</h1> */}
      {/* Your leaderboards content */}
      <GuestModeWarning 
        isOpen={showWarning} 
        onClose={() => setShowWarning(false)} 
      />
    </div>
  );
}
