// ResetPassword.jsx
import React, { useState } from "react";
import "../authentication/scss/confirmnewpassword.scss";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OpenEyeIcon from "../assets/svg/open-eye.svg";
import ClosedEyeIcon from "../assets/svg/closed-eye.svg";

export default function ConfirmNewPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState({
    new: false,
    confirm: false,
  });
  const { token } = useParams();

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }
  
    try {
      const response = await axios.post(
        "https://backend-production-4d3d.up.railway.app/auth/resetpassword/confirm",
        {
          token, // Ensure this is the reset token from the URL
          newPassword,
        }
      );
  
      if (response.status === 200) {
        toast.success("Password has been reset successfully!");
        setTimeout(() => {
          window.location.href = "/login";
        }, 2000);
      } else {
        toast.error("Failed to reset password. Please try again.");
      }
    } catch (error) {
      if (error.response?.status === 400) {
        toast.error("Invalid or expired reset token. Please request a new one.");
      } else {
        toast.error("Error resetting password. Please try again.");
      }
    }
  };
  return (
    <div className="reset-password">
      <div className="container">
        <div className="reset-password-content">
          <div className="reset-password-card">
            <form className="reset-password-form" onSubmit={handleSubmit}>
              <h2 className="reset-password-title">Parolni tasdiqlang</h2>
              <p className="reset-password-subtitle">
                Iltimos, quyida yangi parolingizni kiriting!
              </p>

              <div className="form-group">
                <label>Yangi parol</label>
                <div className="input-wrapper">
                  <input
                    type={passwordVisibility.new ? "text" : "password"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    placeholder="Yangi parolni kiriting"
                  />
                  <button
                    type="button"
                    className="password-toggle"
                    onClick={() => togglePasswordVisibility("new")}
                  >
                    {passwordVisibility.new ? (
                      <img src={OpenEyeIcon}></img>
                    ) : (
                      <img src={ClosedEyeIcon}></img>
                    )}
                  </button>
                </div>
              </div>

              <div className="form-group">
                <label>Yangi parolni kiriting</label>
                <div className="input-wrapper">
                  <input
                    type={
                      passwordVisibility.confirm ? (
                        <img src={OpenEyeIcon}></img>
                      ) : (
                        <img src={ClosedEyeIcon}></img>
                      )
                    }
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    placeholder="Yangi parolni tasdiqlang"
                  />
                  <button
                    type="button"
                    className="password-toggle"
                    onClick={() => togglePasswordVisibility("confirm")}
                  >
                    {passwordVisibility.confirm ? "Hide" : "Show"}
                  </button>
                </div>
              </div>

              <button type="submit" className="reset-password-button">
                Parolni tiklash
              </button>

              <div className="login-prompt">
                Parolingizni esladingizmi?
                <Link to="/login">Kirish</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
}
