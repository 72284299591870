// ForgotPassword.jsx
import React, { useState } from "react";
import "../authentication/scss/forgotpassword.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post("https://backend-production-4d3d.up.railway.app/auth/resetpassword", {
        email,
      });

      if (response.status === 200) {
        toast.success(
          "Qayta tiklash havolasi elektron pochtangizga yuborildi!"
        );
        setEmail(""); // Clear the email field after success
      } else {
        toast.error("Qayta tiklash xatini yubora olmadik!");
      }
    } catch (error) {
      console.error("Error details:", error.response?.data);
      toast.error(
        error.response?.data?.message ||
          "Qayta tiklash xati yuborilmadi. Iltimos, qayta urinib koʻring."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="forgot-password">
      <div className="container">
        <div className="forgot-password-content">
          <div className="forgot-password-card">
            <form className="forgot-password-form" onSubmit={handleSubmit}>
              <h2 className="forgot-password-title">Parolni unutdingizmi?</h2>
              <p className="forgot-password-subtitle">
                Elektron pochta manzilingizni kiriting va biz sizga parolingizni
                tiklash uchun havola yuboramiz!
              </p>

              <div className="form-group">
                <label>Email</label>
                <div className="input-wrapper">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    type="email"
                    placeholder="emailingizni kiriting"
                  />
                </div>
              </div>

              <button
                type="submit"
                className="forgot-password-button"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Yuborilmoqda..." : "Yuborish"}
              </button>

              <div className="login-prompt">
                Parolingizni esladingizmi?
                <Link to="/login">Kirish</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
}
