// SignUp.jsx
import React, { useState } from "react";
import "../authentication/scss/signup.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import OpenEyeIcon from '../assets/svg/open-eye.svg'
import ClosedEyeIcon from "../assets/svg/closed-eye.svg";

const SignUp = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const navigate = useNavigate();

  const toggleInput = () => {
    setPasswordVisibility((prevState) => !prevState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validatePassword(password)) return;

    const loadingToast = toast.loading("Hisobingiz yaratilmoqda...");

    try {
      // Attempt to register - with timeout
      const response = await axios.post("https://backend-production-4d3d.up.railway.app/auth/signup", {
        name,
        surname,
        email,
        password,
      }, { timeout: 5000 });

      const {accessToken, refreshToken, user} = response.data;

    // Store token and clear guest mode immediately
    localStorage.setItem("authToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken); // Store refresh token if needed
    localStorage.removeItem("guestMode");
      
      // Initialize quiz state and clear local storage in parallel
      const initializeState = async () => {
        try {
          await axios.post("https://backend-production-4d3d.up.railway.app/api/quiz/init", {}, {
            headers: { 
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            },
            timeout: 3000
          });
        } catch (initError) {
          console.warn("Failed to initialize quiz state:", initError);
        }
      };

      const clearStorage = () => {
        localStorage.removeItem("quizState");
        localStorage.removeItem("currentQuiz");
      };

      await Promise.all([
        initializeState(),
        Promise.resolve(clearStorage())
      ]);

      // Update loading toast to success
      toast.update(loadingToast, {
        render: "Hisob muvaffaqiyatli yaratildi!",
        type: "success",
        isLoading: false,
        autoClose: 2000
      });

      // Navigate after showing success toast
      setTimeout(() => {
        navigate("/");
      }, 500);

    } catch (error) {
      console.error("Signup error:", error);
      
      // Update loading toast to error
      toast.update(loadingToast, {
        render: error.response?.data?.message || "Hisob yaratishda xatolik, iltimos keyinroq urinib ko'ring!",
        type: "error",
        isLoading: false,
        autoClose: 3000
      });
    }
  };

  const inputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const validatePassword = (password) => {
    const strongPasswordRegex =
      /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[0-9])(?=.{8,})/;
    if (!password) {
      toast.warning("Iltimos parolingini kiriting!");
      return false;
    } else if (!strongPasswordRegex.test(password)) {
      toast.warning(
        "Parol kamida 8 ta belgidan iborat bo'lishi kerak, jumladan raqamlar va maxsus belgilar!"
      );
      return false;
    }
    return true;
  };

  return (
    <div className="signup">
      <div className="container">
        <div className="signup-content">
          <div className="signup-card">
            <form className="signup-form" onSubmit={handleSubmit}>
              <h2 className="signup-title">Hisob yaratish</h2>

              <p className="signup-subtitle">
                Iltimos ma'lumotlaringizni kiriting!
              </p>

              <div className="form-group">
                <label>Ism</label>
                <div className="input-wrapper">
                  <input
                    value={name}
                    onChange={inputChange(setName)}
                    required
                    type="text"
                    placeholder="ismingizni kiriting"
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Familiya</label>
                <div className="input-wrapper">
                  <input
                    value={surname}
                    onChange={inputChange(setSurname)}
                    required
                    type="text"
                    placeholder="familiyangizni kiriting"
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Email</label>
                <div className="input-wrapper">
                  <input
                    value={email}
                    onChange={inputChange(setEmail)}
                    required
                    type="email"
                    placeholder="emailingizni kiriting"
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Parol</label>
                <div className="input-wrapper">
                  <input
                    value={password}
                    onChange={inputChange(setPassword)}
                    required
                    type={passwordVisibility ? "text" : "password"}
                    placeholder="parolingizni kiriting"
                  />
                  <button
                    type="button"
                    className="password-toggle"
                    onClick={toggleInput}
                  >
                    {passwordVisibility ? (
                      <img src={OpenEyeIcon} alt="OpenEye" />
                    ) : (
                      <img src={ClosedEyeIcon} alt="ClosedEye" />
                    )}
                  </button>
                </div>
              </div>

              <button type="submit" className="signup-button">
                Yaratish
              </button>

              <div className="signup-links">
                {/* <Link to="/forgot-password" className="forgot-password-link">
                  Parol esdan chiqdimi?
                </Link> */}

                <div className="login-link">
                  Allaqachon hisobingiz mavjudmi?
                  <Link to="/login">Kirish</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default SignUp;
