import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../scss/tokenExpirationModal.scss';

const TokenExpirationModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  if (!isOpen) return null;

  const continueAsGuest = () => {
    localStorage.setItem('guestMode', 'true');
    localStorage.removeItem('authToken');
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Session Expired</h2>
        <p>Your session has expired. Please log in to continue or explore as a guest.</p>
        <div className="modal-buttons">
          <button 
            className="login-button"
            onClick={() => {
              localStorage.removeItem('authToken');
              navigate('/login');
            }}
          >
            Log In
          </button>
          <button 
            className="guest-button"
            onClick={continueAsGuest}
          >
            Continue as Guest
          </button>
        </div>
      </div>
    </div>
  );
};

export default TokenExpirationModal; 