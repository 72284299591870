import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../scss/ui/profile.scss";
import axios from "axios";
import { jwtDecode } from "jwt-decode"; // ✅ Correct way

const ProfileModal = React.lazy(() => import("../ui/ProfileModal"));
export default function Profile() {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
  
  
 const decodeJWT = (token) => {
   try {
     return jwtDecode(token); // ✅ Proper decoding
   } catch (error) {
     console.error("Invalid token:", error);
     return null;
   }
 };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem("authToken");

        if (!authToken) {
          throw new Error("Auth token not found");
        }

        // Decode the JWT to get the user ID
        const decodedToken = decodeJWT(authToken);
        const userId = decodedToken?.id;

        if (!userId) {
          throw new Error("User ID not found in token");
        }

        const response = await axios.get(
          `https://backend-production-4d3d.up.railway.app/api/user/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        setUserData(response.data);
        setError(null);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError(error.response?.data?.message || "Failed to load profile");
        setUserData(null);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleProfileClick = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };  
  
  const handleClearAuthToken = () => {
    localStorage.removeItem("authToken");
    setIsOpen(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setUserData((prev) => ({
        ...prev,
        avatarUrl: previewUrl,
      }));

      console.log("File selected:", file);
    }
  };

  const handleUserUpdate = (updatedUser) => {
    setUserData(updatedUser);
  };

  const closeOtherModals = () => {
    setIsOpen(false);
  };

  const Icons = {
    Book: () => (
      <svg
        className="icon"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path>
        <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path>
      </svg>
    ),
    Award: () => (
      <svg
        className="icon"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
      >
        <circle cx="12" cy="8" r="6"></circle>
        <path d="M15.477 12.89 17 22l-5-3-5 3 1.523-9.11"></path>
      </svg>
    ),
    Chart: () => (
      <svg
        className="icon"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path d="M3 3v18h18"></path>
        <path d="m19 9-5 5-4-4-3 3"></path>
      </svg>
    ),
    User: () => (
      <svg
        className="icon"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
        <circle cx="12" cy="7" r="4"></circle>
      </svg>
    ),
    Settings: () => (
      <svg
        className="icon"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"></path>
        <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1Z"></path>
      </svg>
    ),
    Logout: () => (
      <svg
        className="icon"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
        <polyline points="16 17 21 12 16 7"></polyline>
        <line x1="21" y1="12" x2="9" y2="12"></line>
      </svg>
    ),
  };

  if (!userData) {
    return <div>Yuklanmoqda...</div>;
  }

   return (
     <div className="profile">
       <div className="profile-header">
         <div className="profile-avatar">
           {userData.avatarUrl ? (
             <img src={userData.avatarUrl} alt={userData.name} />
           ) : (
             <span>
               {userData.name[0]}
               {userData.surname[0]}
             </span>
           )}
         </div>
         <div className="profile-header-info">
           <h3>{`${userData.name} ${userData.surname}`}</h3>
           <p>{userData.email}</p>
         </div>
       </div>

       <div className="profile-stats">
         <h4>Statistika</h4>
         <div className="profile-stats-grid">
           <div className="profile-stats-item">
             <Icons.Book />
             <p className="value">{userData.stats.solvedQuestions}</p>
             <p className="label">Yechildi</p>
           </div>
           <div className="profile-stats-item">
             <Icons.Award />
             <p className="value">{userData.stats.totalPoints}%</p>
             <p className="label">Foiz</p>
           </div>
           <div className="profile-stats-item">
             <Icons.Chart />
             <p className="value">#{userData.stats.ranking}</p>
             <p className="label">Rank</p>
           </div>
         </div>
       </div>

       <div className="profile-menu">
         <div className="profile-menu-item">
           <Icons.Settings />
           <span
             onClick={() => setIsModalOpen(true)}
             style={{ cursor: "pointer" }}
           >
             Akkaunt
           </span>
         </div>

         <ProfileModal
           isOpen={isModalOpen}
           onClose={() => setIsModalOpen(false)}
           userData={userData}
           onUpdate={handleUserUpdate}
           closeOtherModals={closeOtherModals}
         />

         <Link
           to="/login"
           className="profile-menu-item"
           onClick={handleClearAuthToken}
         >
           <Icons.Logout />
           <span>Chiqish</span>
         </Link>
       </div>
     </div>
   );
}
