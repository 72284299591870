import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import "../scss/home.scss";
import TokenExpirationModal from "./TokenExpirationModal";


const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [remainingTime, setRemainingTime] = useState(3000);
  const [quizStarted, setQuizStarted] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingState, setLoadingState] = useState('');
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);


  // Get current question safely with null check
  const currentQuestion = questions && questions.length > 0 ? questions[currentQuestionIndex] : null;


  const isTokenExpired = (token) => {
    try {
      const decoded = jwtDecode(token);
      if (!decoded.exp) {
        return true; // If no expiration claim, treat it as expired
      }

      const currentTime = Math.floor(Date.now() / 1000);
      return decoded.exp < currentTime;
    } catch (error) {
      console.error("Invalid token:", error);
      return true; // Treat invalid token as expired
    }
  };



  // Get user ID from token
const getUserId = () => {
  const token = localStorage.getItem("authToken");

  if (!token) return null;

  if (isTokenExpired(token)) {
    console.log("Token is expired. Showing modal...");
    setIsModalOpen(true); // Open modal instead of removing token immediately
    return null;
  }

  try {
    const decoded = jwtDecode(token);
    return decoded.id || null;
  } catch (error) {
    console.error("Error decoding token:", error.message);
    return null;
  }


  };
  
  useEffect(() => {
    setIsModalOpen(true); // Force it to appear
  }, []);


useEffect(() => {
  const token = localStorage.getItem("authToken");

  if (token && isTokenExpired(token)) {
    toast.error("Sessiya eskirdi. Iltimos hisobingiz bilan qaytadan kiring!");
    navigate("/login"); // Navigate immediately instead of showing a modal
  }
}, [navigate]);


  // Handle starting the quiz
  const handleStartQuiz = async () => {
    try {
      const userId = getUserId();
      if (!userId) {
        toast.error("Auntifikatisiya kerak. Iltimos hisobingiz bilan kiring!");
        navigate('/login');
        return;
      }
      

      setLoadingState('Starting quiz...');
      const token = localStorage.getItem("authToken");
      
      const response = await axios.post(
        'https://backend-production-4d3d.up.railway.app/api/quiz/start',
        {},
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.quizState && response.data.quizState.questions) {
        setQuestions(response.data.quizState.questions);
        setCurrentQuestionIndex(response.data.quizState.currentQuestionIndex || 0);
        setRemainingTime(response.data.quizState.remainingTime || 3000);
        setUserAnswers(response.data.quizState.userAnswers || {});
        setQuizStarted(true);
      } else {
        toast.error("Serverdan savol qabul qilinmadi!");
      }
    } catch (error) {
      console.error("Error starting quiz:", error);
      if (error.response?.status === 401 || error.response?.status === 403) {
        toast.error("Sessiya eskirdi. Iltimos hisobingiz bilan qaytadan kiring!");
        window.location.href('/login');
      } else {
        toast.error(error.response?.data?.error || "Testni boshlashda muammo!");
      }
    } finally {
      setLoadingState('');
      setLoading(false);
    }
  };

  // Handle answering a question
  const handleAnswer = async (questionId, selectedAnswer) => {
    if (!questionId) return;
    
    setUserAnswers(prev => {
      const newAnswers = {
        ...prev,
        [questionId]: selectedAnswer
      };
      
      const userId = getUserId();
      if (userId) {
        const storageKey = `quizState_${userId}`;
        const stateToSave = {
          questions,
          currentQuestionIndex,
          userAnswers: newAnswers,
          remainingTime,
          lastUpdated: new Date().toISOString(),
          quizStarted
        };
        localStorage.setItem(storageKey, JSON.stringify(stateToSave));
      }
      
      return newAnswers;
    });
  };

  // Handle moving to next question
  const handleNextQuestion = async () => {
    if (!questions || currentQuestionIndex >= questions.length - 1) return;
    
    setCurrentQuestionIndex(prev => prev + 1);
    await saveCurrentState();
  };

  // Handle finishing the quiz
  const handleFinishQuiz = async () => {
    if (!window.confirm("Testni tugatishga ishonchingiz komilmi?")) {
      return;
    }

    setIsSubmitting(true);
    const loadingToast = toast.loading("Javoblaringiz saqlanmoqda...");

    try {
      // Format the questions data properly
      const formattedQuestions = questions.map((question) => ({
        questionId: question._id,
        question: question.question,
        options: question.options,
        userAnswer: userAnswers[question._id] || "",
        correctAnswer: question.correctAnswer,
      }));

      const quizData = {
        questions: formattedQuestions,
        totalQuestions: questions.length,
        duration: Math.floor((3000 - remainingTime) / 60)
      };

      console.log("Submitting quiz data:", quizData); // Debug log

      const response = await axios.post(
        "https://backend-production-4d3d.up.railway.app/api/results",
        quizData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.update(loadingToast, {
        render: "Muvaffaqiyatli saqlandi!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });

      setQuizCompleted(true);
      navigate("/quiz-history");
    } catch (error) {
      console.error("Error saving results:", error);
      toast.update(loadingToast, {
        render: error.response?.data?.error || "Testni yakunlashda xatolik!",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Save current state
  const saveCurrentState = async () => {
    if (!questions || questions.length === 0) return;
    
    setSaving(true);
    try {
      const stateToSave = {
        currentQuestionIndex,
        userAnswers,
        remainingTime
      };

      await axios.post(
        'https://backend-production-4d3d.up.railway.app/api/quiz/save-state',
        stateToSave,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
            'Content-Type': 'application/json'
          }
        }
      );
    } catch (error) {
      console.error("Error saving state:", error);
      toast.error("Test javoblaringiz holatini saqlashda xatolik!");
    } finally {
      setSaving(false);
    }
  };

  // Timer effect
  useEffect(() => {
    if (!quizStarted || quizCompleted || !questions) return;

    const timer = setInterval(() => {
      setRemainingTime(prev => {
        const newTime = prev - 1;
        if (newTime <= 0) {
          clearInterval(timer);
          handleTimeUp(); // Call handleTimeUp directly when time is up
          return 0;
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [quizStarted, quizCompleted, questions]);

  // Load saved state effect
  useEffect(() => {
    const loadSavedState = async () => {
      const userId = getUserId();
      if (!userId) return;

      try {
        const response = await axios.get('https://backend-production-4d3d.up.railway.app/api/quiz/state', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("authToken")}`
          }

        });

        if (response.data.quizState) {
          setQuestions(response.data.quizState.questions || null);
          setCurrentQuestionIndex(response.data.quizState.currentQuestionIndex || 0);
          setRemainingTime(response.data.quizState.remainingTime || 3000);
          setUserAnswers(response.data.quizState.userAnswers || {});
          setQuizStarted(true);
        }
      } catch (error) {
        if (error.response?.status !== 404) {
          console.error("Error loading saved state:", error);
        }
      } finally {
        setLoading(false);
      }
    };

    loadSavedState();
  }, []);

  // Auto-save effect
  useEffect(() => {
    if (!quizStarted || !questions || quizCompleted) return;

    const saveState = async () => {
      try {
        await saveCurrentState();
      } catch (error) {
        console.error("Error in auto-save:", error);
      }
    };

    const autoSaveInterval = setInterval(saveState, 30000); // Save every 30 seconds
    return () => clearInterval(autoSaveInterval);
  }, [quizStarted, questions, quizCompleted]);

  // Handle time up
  const handleTimeUp = async () => {
    if (saving || isSubmitting || !questions) return;

    setIsSubmitting(true);
    const loadingToast = toast.loading("Vaqt tugadi!");

    try {
      await handleFinishQuiz();
    } catch (error) {
      console.error("Error handling time up:", error);
      toast.update(loadingToast, {
        render: "Tesni yakunlashda xatolik!",
        type: "error",
        isLoading: false,
        autoClose: 3000
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Add this effect to handle page visibility
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden && quizStarted && !quizCompleted) {
        // Save current state when page becomes hidden
        localStorage.setItem('lastActiveTime', Date.now().toString());
      } else {
        // Check time passed when page becomes visible
        const lastActiveTime = localStorage.getItem('lastActiveTime');
        if (lastActiveTime) {
          const timePassed = (Date.now() - parseInt(lastActiveTime)) / (1000 * 60);
          if (timePassed >= 50) {
            handleTimeUp();
          }
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [quizStarted, quizCompleted]);

  // Add this confirmation before leaving
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (quizStarted && !isSubmitting) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [quizStarted, isSubmitting]);

  // Add this effect to sync with localStorage and backend
  useEffect(() => {
    const syncQuizState = async () => {
      if (!quizStarted || !questions || !questions.length) return;
      
      const userId = getUserId();
      if (!userId) return;

      const storageKey = `quizState_${userId}`;
      const stateToSave = {
        questions,
        currentQuestionIndex,
        userAnswers,
        remainingTime,
        lastUpdated: new Date().toISOString(),
        quizStarted
      };

      // Save to localStorage
      localStorage.setItem(storageKey, JSON.stringify(stateToSave));

      // Save to backend
      try {
        await axios.post(
          'https://backend-production-4d3d.up.railway.app/api/quiz/save-state',
          stateToSave,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
          }
        );
      } catch (error) {
        console.error("Error syncing state:", error);
      }
    };

    syncQuizState();
  }, [currentQuestionIndex, userAnswers, remainingTime, quizStarted, questions]);

  // Add cleanup effect
  useEffect(() => {
    return () => {
      if (quizStarted) {
        saveCurrentState();
      }
    };
  }, [quizStarted]);

  // Add this inside your component, after questions state is set
  useEffect(() => {
    if (questions && questions.length > 0) {
      console.log('Loaded questions:', questions.map(q => ({
        id: q._id,
        hasCorrectAnswer: !!q.correctAnswer,
        correctAnswer: q.correctAnswer
      })));
    }
  }, [questions]);

  console.log("Starting quiz...");
  console.log("Finishing quiz...");
  console.log("Timer running...");

  return (
    <div className="quiz-container">
      {loadingState && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>{loadingState}</p>
        </div>
      )}
      
      {!quizStarted ? (
        <div className="quiz-card start-card">
          <div className="quiz-intro">
            <h1>Huquqdan savollar!</h1>
            <div className="quiz-info">
              <div className="info-item">
                <span className="icon">⏱️</span>
                <span>50 daqiqa</span>
              </div>
              <div className="info-item">
                <span className="icon">❓</span>
                <span>ochiq test</span>
              </div>
              <div className="info-item">
                <span className="icon">📝</span>
                <span>avto saqlab qolish</span>
              </div>
            </div>
            <button 
              onClick={handleStartQuiz} 
              disabled={loading}
              className="start-button"
            >
              {loading ? 'Yuklanmoqda...' : 'Boshlash'}
            </button>
          </div>
        </div>
      ) : (
        <div className="quiz-content">
          {!questions ? (
            <div className="loading-message">
              <p>Yuklanmoqda...</p>
            </div>
          ) : !currentQuestion ? (
            <div className="error-message">
              <p>Savollar topilmadi</p>
              <button onClick={handleStartQuiz}>Qayta harakat qilib ko'ring</button>
            </div>
          ) : (
            <>
              <div className="quiz-header">
                <div className="progress-bar">
                  <div 
                    className="progress" 
                    style={{ width: `${(currentQuestionIndex / questions.length) * 100}%` }}
                  />
                </div>
                <div className="quiz-info-bar">
                  <span className="question-counter">
                    Savol {currentQuestionIndex + 1}/{questions.length}
                  </span>
                  <span className="timer">
                    {Math.floor(remainingTime / 60)}:{(remainingTime % 60).toString().padStart(2, '0')}
                  </span>
                </div>
              </div>

              <div className="question-card">
                <h2 className="question-text">
                  {currentQuestion.question}
                </h2>

                <div className="options-grid">
                  {currentQuestion.options?.map((option, index) => (
                    <button
                      key={index}
                      onClick={() => handleAnswer(currentQuestion._id, String.fromCharCode(65 + index))}
                      className={`option-button ${
                        userAnswers[currentQuestion._id] === String.fromCharCode(65 + index)
                          ? 'selected'
                          : ''
                      }`}
                    >
                      <span className="option-label">{String.fromCharCode(65 + index)}</span>
                      <span className="option-text">{option}</span>
                    </button>
                  ))}
                </div>

                <div className="navigation-buttons">
                  {saving && <span className="saving-indicator"></span>}
                  <button 
                    onClick={currentQuestionIndex === questions.length - 1 ? handleFinishQuiz : handleNextQuestion}
                    className="next-button"
                    disabled={!userAnswers[currentQuestion._id] || isSubmitting}
                  >
                    {currentQuestionIndex === questions.length - 1 ? 'Finish Quiz' : 'Next Question'}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Home;
