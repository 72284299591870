import React, { useEffect, useState } from "react";
import "../scss/navbar.scss";
import SearchIcon from "../assets/svg/search.svg";
import { Link } from "react-router-dom";
import ProfileDefault from "../assets/img/user-default-profile.png";
import SettingsIcon from "../assets/svg/settings.svg";
import NotificationIcon from "../assets/svg/notification.svg";
import NotificationFilledIcon from "../assets/svg/notification-filled.svg";
import SupportIcon from '../assets/svg/support.svg'
import LogOutIcon from "../assets/svg/log-out.svg";
import { useLocation } from "react-router-dom";
import Profile from "../ui/Profile";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';

export default function Navbar() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [newNotifications, setNewNotifications] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    setLoggedIn(!!authToken);
  }, []);

  const handleLogout = async () => {
  
  const authToken = localStorage.getItem("authToken");
  const refreshToken = localStorage.getItem("refreshToken");

  if (authToken || refreshToken) {
    try {
      // Clear quiz state on server if needed
      await axios.post("https://backend-production-4d3d.up.railway.app/auth/logout", null, {
        headers: { Authorization: `Bearer ${authToken}` }, // Use the correct token variable
      });

      localStorage.removeItem("authToken");
      localStorage.removeItem("refreshToken");
// localStorage.clear()
      toast.success("Muvaffaqiyatli tark etildi!");
      navigate("/login");
    } catch (error) {
      console.error("Logout error:", error);
      toast.error("Hisobdan chiqishda xatolik!");
    }
  }
};

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const location = useLocation();

  const checkPageLocation = () => {
    switch (location.pathname) {
      case "/":
        return "Asosiy";
      case "/leaderboards":
        return "Reytinglar";
      case "/quiz-history":
        return "Test tarixi";
      default:
        return "Quiz App";
    }
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="navbar-content">
          <h2 className="navbar-logo">{checkPageLocation()}</h2>

          <div className="navbar-menu">
            <div className="navbar-searchbar">
              <img src={SearchIcon} alt="Search" />
              <input type="text" placeholder="Qidiruv" />
            </div>

            {/* Settings with Dropdown */}
            <div className="relative navbar-settings-group">
              <div className="navbar-settings">
                <img src={SettingsIcon} alt="Settings" />
              </div>
              <div className="navbar-settings-dropdown">
                <ul>
                  <li onClick={openModal}>
                    <img src={SettingsIcon} alt="" />
                    Sozlamalar
                  </li>
                  <li>
                    <img src={SupportIcon} alt="" />
                    Bog'lanish
                  </li>
                  {loggedIn ? (
                    <li onClick={handleLogout}>
                      <img src={LogOutIcon} alt="" />
                      Tark etish
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
            </div>
            {isModalOpen && (
              <div className="modal-overlay" onClick={closeModal}>
                <div
                  className="modal-content"
                  onClick={(e) => e.stopPropagation()} // Prevents closing when clicking inside the modal
                >
                  <div className="modal-close" onClick={closeModal}>
                    &times;
                  </div>
                  <h2>Sozlamalar</h2>
                  {/* Your modal content here */}
                  Tez orada qo'shiladi!
                  {/* <p>Sozlamalar:.</p> */}
                </div>
              </div>
            )}

            {/* Notifications */}
            <div className="relative navbar-notifications-group">
              <div className="navbar-notifications">
                <img
                  src={
                    newNotifications ? NotificationFilledIcon : NotificationIcon
                  }
                  alt="Notifications"
                />
              </div>
              <div className="navbar-notifications-dropdown">
                {newNotifications ? (
                  <ul>
                    <li>Yangi bildirishnomalar</li>
                  </ul>
                ) : (
                  <p>Bildirishnomalar yo'q!</p>
                )}
              </div>
            </div>

            {/* Profile or Login */}
            {loggedIn ? (
              <div className="relative navbar-profile-group">
                <div className="navbar-profile">
                  <img src={ProfileDefault} alt="Profile" />
                </div>
                <div className="profile-dropdown">
                  <Profile />
                </div>
              </div>
            ) : (
              <Link className="navbar-login" to="/login">
                Login
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
