import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../scss/quizhistory.scss";

const QuizHistoryDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [quizDetail, setQuizDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQuizDetail = async () => {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        setError("Authentication required");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `https://backend-production-4d3d.up.railway.app/api/results/${id}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.data) {
          throw new Error('No data received from server');
        }

        // Format the data for display
        const formattedData = {
          ...response.data,
          questions: response.data.questions.map(q => ({
            ...q,
            isCorrect: q.userAnswer === q.correctAnswer,
            options: Array.isArray(q.options) ? q.options : [],
          }))
        };

        setQuizDetail(formattedData);
      } catch (error) {
        console.error('Error fetching quiz detail:', error);
        setError(error.response?.data?.error || error.message);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchQuizDetail();
    }
  }, [id]);

  const getOptionLabel = (index) => {
    return String.fromCharCode(65 + index); // Converts 0 to 'A', 1 to 'B', etc.
  };

  const renderAnswerComparison = (question) => {
    const userAnswerIndex = question.userAnswer.charCodeAt(0) - 65;
    const correctAnswerIndex = question.correctAnswer.charCodeAt(0) - 65;

    return (
      <div className="answer-comparison">
        <div className={`answer-box ${question.isCorrect ? 'correct' : 'incorrect'}`}>
          <div className="answer-header">
            <span className="answer-label">Sizning javobingiz:</span>
            <span className={`answer-indicator ${question.isCorrect ? 'correct' : 'incorrect'}`}>
              {question.isCorrect ? '✓' : '✗'}
            </span>
          </div>
          <div className="answer-content">
            <span className="answer-letter">{question.userAnswer}</span>
            <span className="answer-text">
              {question.options[userAnswerIndex]}
            </span>
          </div>
        </div>
        
        {!question.isCorrect && (
          <div className="answer-box correct">
            <div className="answer-header">
              <span className="answer-label">To'g'ri javob:</span>
              <span className="answer-indicator correct">✓</span>
            </div>
            <div className="answer-content">
              <span className="answer-letter">{question.correctAnswer}</span>
              <span className="answer-text">
                {question.options[correctAnswerIndex]}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderOption = (option, index, question) => {
    const optionLabel = String.fromCharCode(65 + index);
    const isCorrect = optionLabel === question.correctAnswer;
    const isSelected = optionLabel === question.userAnswer;

    return (
      <div 
        className={`option ${isCorrect ? 'correct-option' : ''} 
          ${isSelected && !isCorrect ? 'wrong-option' : ''} 
          ${isSelected ? 'selected-option' : ''}`}
      >
        <div className="option-content">
          <span className="option-label">{optionLabel}</span>
          <span className="option-text">{option}</span>
        </div>
        {isCorrect && <span className="option-icon correct">✓</span>}
        {isSelected && !isCorrect && <span className="option-icon incorrect">✗</span>}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="quiz-history-container">
        <div className="quiz-history-card">
          <div className="loading">Yuklanmoqda...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="quiz-history-container">
        <div className="quiz-history-card">
          <div className="error-message">{error}</div>
        </div>
      </div>
    );
  }

  if (!quizDetail) {
    return (
      <div className="quiz-history-container">
        <div className="quiz-history-card">
          <div className="error-message">Quiz topilmadi!</div>
        </div>
      </div>
    );
  }

  return (
    <div className="quiz-history-container">
      <div className="quiz-history-card detail-view">
        <div className="quiz-detail-header">
          <button className="back-button" onClick={() => navigate('/quiz-history')}>
            ← Orqaga
          </button>
          <h2 className="quiz-history-title">Test natijalari</h2>
        </div>

        {quizDetail && (
          <>
            <div className="quiz-detail-summary">
              <div className="summary-card">
                <div className="summary-icon">🎯</div>
                <div className="summary-label">Ball</div>
                <div className="summary-value">
                  {quizDetail.score}/{quizDetail.totalQuestions}
                </div>
              </div>
              <div className="summary-card">
                <div className="summary-icon">📊</div>
                <div className="summary-label">Foiz</div>
                <div className="summary-value">
                  {((quizDetail.score / quizDetail.totalQuestions) * 100).toFixed(1)}%
                </div>
              </div>
              <div className="summary-card">
                <div className="summary-icon">⏱️</div>
                <div className="summary-label">Vaqt</div>
                <div className="summary-value">
                  {quizDetail.duration || 'N/A'} daqiqa
                </div>
              </div>
            </div>

            <div className="questions-review">
              {quizDetail.questions.map((question, index) => (
                <div key={index} className="question-card">
                  <div className="question-header">
                    <span className="question-number">Savol {index + 1}</span>
                    <span className={`status-badge ${question.isCorrect ? 'correct' : 'incorrect'}`}>
                      {question.isCorrect ? "✓ To'g'ri" : "✗ No'to'g'ri"}
                    </span>
                  </div>
                  
                  <div className="question-text">{question.question}</div>
                  
                  {renderAnswerComparison(question)}
                  
                  <div className="options-container">
                    {question.options.map((option, optIndex) => (
                      <div key={optIndex} className="option-wrapper">
                        {renderOption(option, optIndex, question)}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default QuizHistoryDetail;
