import React, { useState } from "react";
import "../authentication/scss/login.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import OpenEyeIcon from "../assets/svg/open-eye.svg";
import ClosedEyeIcon from "../assets/svg/closed-eye.svg";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const navigate = useNavigate();

  const togglePassword = () => {
    setPasswordVisibility((prev) => !prev);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Show loading toast
    const loadingToast = toast.loading("Kirilmoqda...");

    try {
      // First, attempt to login - with timeout
      const loginResponse = await axios.post(
        "https://backend-production-4d3d.up.railway.app/auth/login",
        {
          email,
          password,
        },
        { timeout: 5000 }
      ); // 5 second timeout

      const { accessToken, refreshToken, user } = loginResponse.data;

      // Store accessToken and refreshToken in localStorage
      localStorage.setItem("authToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.removeItem("guestMode");

      // Clear local storage in parallel
      const clearLocalStorage = () => {
        localStorage.removeItem("quizState");
        localStorage.removeItem("currentQuiz");
      };

      // Clear quiz state in parallel
      const clearQuizState = async () => {
        try {
          await axios.delete("https://backend-production-4d3d.up.railway.app/api/quiz/state", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            timeout: 3000, // 3 second timeout
          });
        } catch (clearError) {
          console.warn("Failed to clear quiz state:", clearError);
        }
      };

      // Run cleanup operations in parallel
      await Promise.all([
        clearQuizState(),
        Promise.resolve(clearLocalStorage()),
      ]);

      // Update loading toast to success
      toast.update(loadingToast, {
        render: "Login muvaffaqiyatli!",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });

      // Navigate after showing success toast
      setTimeout(() => {
        navigate("/");
      }, 500);
    } catch (error) {
      console.error("Login error:", error);

      // Update loading toast to error
      toast.update(loadingToast, {
        render: getErrorMessage(error),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  // Helper function to get error message
  const getErrorMessage = (error) => {
    if (error.response) {
      if (error.response.status === 400) return "Foydalanuvchi topilmadi!";
      if (error.response.status === 401) return "Parol xato!";
      return error.response.data.message || "Kutilmagan muammo yuz keldi!";
    }
    if (error.code === "ECONNABORTED")
      return "Serverda muammo, iltimos birozdan so'ng qayta harakat qilib ko'ring!";
    if (error.request)
      return "Serverdan javob yo'q, mobil internetingizni tekshirib ko'ring!";
    return "Hisobga kirib bo'lmadi, iltimos keyinroq harakat qilib ko'ring!";
  };

  return (
    <div className="login">
      <div className="container">
        <div className="login-content">
          <div className="login-card">
            <form className="login-form" onSubmit={handleSubmit}>
              <h2 className="login-title">Qayta kirish</h2>
              <p className="login-subtitle">
                Iltimos ma'lumotlaringizni kiriting!
              </p>

              <div className="form-group">
                <label>Email</label>
                <div className="input-wrapper">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="emailingizni kiriting"
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Parol</label>
                <div className="input-wrapper">
                  <input
                    type={passwordVisibility ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    placeholder="parolingizni kiriting"
                  />
                  <button
                    type="button"
                    className="password-toggle"
                    onClick={togglePassword}
                  >
                    {passwordVisibility ? (
                      <img src={OpenEyeIcon} alt="Open Eye" />
                    ) : (
                      <img src={ClosedEyeIcon} alt="Closed Eye" />
                    )}
                  </button>
                </div>
              </div>

              <div className="remember-forgot">
                {/* <label className="remember-me">
                  <input type="checkbox" />
                  <span>Eslab qolish</span>
                </label> */}
                <Link to="/forgot-password" className="forgot-link">
                  Parolni unutdingizmi?
                </Link>
              </div>

              <button type="submit" className="login-button">
                Kirish
              </button>

              <div className="signup-prompt">
                Yangi hisob yaratmoqchimisiz?
                <Link to="/signup">Hisob yaratish</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
}
